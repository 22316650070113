#feedcontainer {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.timelineimage{
  margin: 40px;
  width: 150px;
  height: 150px;
  background: pink;
  text-align: center;
  border-radius: 50%;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
}
.main{
  text-align: center;
}
.main h1{
  font-size: 1.5rem;
}
.main button{
  float:right;
}
.login-notice-text{
  color: rgb(201, 32, 88);
  margin-bottom: 0.5rem;
  display: inline-block;
}
.login-notice-text p{
  margin: 0px auto;
  text-align: left;
}

.text-red{
  color: red;
}
.text-left{
  text-align: left;
}

/* PDF印刷部分 */
.dl-btn-wrapper{
  /* text-align: center; */
  margin: 10px auto;
}
.dl-btn-wrapper button{
  padding: 10px 10px;
  background-color: skyblue;
}
.dl-btn-wrapper button:hover{
  cursor: pointer;
}
.dl-btn-wrapper p{
  margin-top: 3px;
  margin-bottom: 0px;
}

.sheet .contents_ja{
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
}
.sheet .contents_ja h2{
  margin: 0 auto;
}
.sheet .contents_ja .title_en{
  font-size: 0.9rem;
  margin-bottom: 2em;
}
.sheet .contents_ja p{
  margin: 0 auto;
}

.sheet .contents_en{
  margin-bottom: 2em;
}

.sheet .result{
  text-align: center;
  font-size: 0.9rem;
}

.sheet .result table{
  border-collapse: collapse;
  margin: 1em auto 2em auto;
}
.sheet .result table th{
  padding: 10px 10px;
}
.sheet .result table td{
  padding: 10px 10px;
}

.sheet .t-left{
  text-align: left;
}

.sheet .clinic-info{
  margin: 0 auto;
  /* width: 70%; */
}

.sheet .clinic-info .clinic-info-wrapper{
  margin-top: 10px;
}

.sheet .clinic-info .clinic-info-wrapper p{
  font-size: 0.8em;
}

.sheet .clinic-info .clinic-info-wrapper-renmei p{
  font-size: 0.6em;
}

.sheet .kakuin-wrapper{
  text-align: right;
}

.sheet .kakuin{
  height: 80px;
  width: 80px;
  margin: 0 auto 0 0;
}
.sheet .kakuin-all{
  height: 80px;
  width: 80px;
  margin: 0 3px 0 0;
}
.sheet td{
  padding: 0px 5px;
}
.kanagawa-sheet{
  font-size: 0.95rem;
}
.tokyo-sheet{
  font-size: 0.95em;
}
.chiba-sheet{
  font-size: 1.0rem;
}
.okayama-sheet{
  font-size: 0.95em;
}
.chiba-info{
  height: 190px;
  margin: 0 auto 0 0;
}

.textdecoration_linethrough_short {
  /* text-decoration: line-through; */
  position: relative;
}
.textdecoration_linethrough_short::before{
  content: "";
  position: absolute;
  border-top: 3px double;
  width: 200px;
  top: 40%;
}

.textdecoration_linethrough {
  /* text-decoration: line-through; */
  position: relative;
}
.textdecoration_linethrough::before{
  content: "";
  position: absolute;
  border-top: 3px double;
  width: 380px;
  top: 40%;
}

.textdecoration_linethrough_long {
  /* text-decoration: line-through; */
  position: relative;
}
.textdecoration_linethrough_long::before{
  content: "";
  position: absolute;
  border-top: 3px double;
  width: 750px;
  top: 40%;
}
